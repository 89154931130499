@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;800&family=Inter:wght@600&display=swap");
* {
  box-sizing: border-box;
}

h4 {
  color: #424242;
}

body {
  background-color: #f5f6fa;
}
